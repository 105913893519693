import { format } from 'date-fns';
import { de, enGB } from 'date-fns/locale';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import BlogTeaser from '../components/BlogTeaser';
import Layout, { Section } from '../components/Layout';
import { Seo } from '../components/Seo';
import { Text, Title } from '../components/Text';
import { sprinkles } from '../sprinkles.css';

/* recursively traverse the rich text json and extract all text nodes
/* example richt text string:
"{\"nodeType\":\"document\",\"data\":{},\"content\":[{\"nodeType\":\"paragraph\",\"data\":{},\"content\":[{\"nodeType\":\"text\",\"value\":\"Die Verbindung zwischen dem Hinweisgeberschutzgesetz und dem Datenschutz ist ein komplexes Thema, das viel Diskussion und Überlegung erfordert. Das Hinweisgeberschutzgesetz, oft als \",\"marks\":[],\"data\":{}}]},{\"nodeType\":\"embedded-asset-block\",\"data\":{\"target\":{\"sys\":{\"id\":\"7CChQyNp5P2GgRfsBylgEV\",\"type\":\"Link\",\"linkType\":\"Asset\"}}},\"content\":[]},{\"nodeType\":\"hr\",\"data\":{},\"content\":[]},{\"nodeType\":\"paragraph\",\"data\":{},\"content\":[{\"nodeType\":\"text\",\"value\":\"\",\"marks\":[],\"data\":{}},{\"nodeType\":\"hyperlink\",\"data\":{\"uri\":\"blog/Hinweisgeberschutzgesetz-Ueberblick-wichtigste/\"},\"content\":[{\"nodeType\":\"text\",\"value\":\"HinSchG\",\"marks\":[],\"data\":{}}]},{\"nodeType\":\"text\",\"value\":\" abgekürzt, hat wichtige  Auswirkungen \",\"marks\":[],\"data\":{}},{\"nodeType\":\"embedded-entry-inline\",\"data\":{\"target\":{\"sys\":{\"id\":\"3RnWnbzbVC4SVOgdAquRJb\",\"type\":\"Link\",\"linkType\":\"Entry\"}}},\"content\":[]},{\"nodeType\":\"text\",\"value\":\" auf die Verarbeitung personenbezogener Daten, ins
*/
const contentfulRichTextExcerpt = (length: number, json: string) => {
	const content = JSON.parse(json);
	let excerpt = '';
	const traverse = (node: any) => {
		if (node.nodeType.startsWith('heading-')) return;
		if (excerpt.length > length) return;
		if (node.nodeType === 'text') {
			excerpt += ' ' + node.value;
		} else if (node.content) {
			node.content.forEach(traverse);
		}
	};
	content.content.forEach(traverse);
	return excerpt.substring(0, length).trim();
};

const formatDate = (isoDate: string, language: string) => {
	const date = new Date(isoDate);
	switch (language) {
		case 'de':
			return format(date, 'dd. MMMM yyyy', { locale: de });
		case 'en':
			return format(date, 'MMMM dd, yyyy', { locale: enGB });
		default:
			throw new Error(`Unknown language ${language}`);
	}
};

const KnowledgePage = ({
	data: { blogs, contentfulBlogs },
}: {
	data: {
		locales: { edges: Queries.LocaleEdge[] };
		blogs: { nodes: any[] };
		contentfulBlogs: { nodes: any[] };
	};
}) => {
	const {
		t,
		i18n: { language },
	} = useTranslation('KnowledgePage');
	return (
		<Layout>
			{/* <Title level="section">{t('BlogPosts')}</Title> */}
			<Section
				width="fill"
				direction="horizontal"
				align="start"
				justify="left"
				wrap
				gap="large"
			>
				<div
					className={sprinkles({
						maxWidth: 'fourhalf',
						boxSizing: 'content-box',
					})}
				>
					<Section>
						<Title level="title">{t('Title')}</Title>
						<Text>{t('Description')}</Text>
					</Section>
				</div>
				{blogs.nodes
					.concat(
						contentfulBlogs.nodes
							.filter((n) => n.node_locale.startsWith(language))
							.map((n) => ({
								excerpt: contentfulRichTextExcerpt(250, n.text.raw),
								frontmatter: {
									title: n.title,
									published_at: formatDate(n.updatedAt, language),
									lastmod: n.updatedAt,
									path: n.slug,
									author: n.author,
									featured_image: {
										src: {
											childImageSharp: {
												gatsbyImageData: n.image.gatsbyImageData,
											},
										},
										alt: n.image.title,
									},
								},
							})),
					)
					.sort((a, b) => new Date(b.lastmod).getTime() - new Date(a.lastmod).getTime())
					.map((node) => (
						<BlogTeaser blog={node} key={node.id} />
					))}
			</Section>
		</Layout>
	);
};

export default KnowledgePage;

export const Head = () => {
	return <Seo />;
};

export const pageQuery = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
		contentfulBlogs: allContentfulBlogPost {
			nodes {
				__typename
				id
				title
				text {
					raw
				}
				image {
					title
					gatsbyImageData(layout: FULL_WIDTH)
				}
				author {
					name
				}
				slug
				readTimeMin
				updatedAt
				node_locale
			}
		}
		blogs: allMdx(
			filter: {
				fileAbsolutePath: { glob: "**/blog/**" }
				frontmatter: { language: { eq: $language }, path: { ne: "how-to-implement" } }
			}
		) {
			nodes {
				id
				__typename
				frontmatter {
					title
					published_at
					path
					author
					featured_image {
						src {
							childImageSharp {
								gatsbyImageData(layout: FULL_WIDTH)
							}
						}
						alt
					}
				}
				excerpt(pruneLength: 250)
				internal {
					contentFilePath
				}
			}
		}
	}
`;
